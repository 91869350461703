.pagination-container {
    justify-content: center;
  display: flex;
  list-style-type: none;
  margin: 2%;
}



  .pagination-item {
    padding: 0 13px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
  }

  @media only screen and (max-width: 800px) {
    .pagination-item {
      padding: 0 12px;
      height: 32px;
      text-align: center;
      margin: auto 2px;
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      border-radius: 16px;
      line-height: 1.43;
      font-size: 13px;
      min-width: 32px;
    }
  
  }

    .pagination-item .dots:hover {
      background-color: transparent;
      cursor: default;
    }
    .pagination-item:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    .pagination-item .selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

      .arrow::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      .arrow .left {
        transform: rotate(-135deg) translate(-50%);
      }

      .arrow .right {
        transform: rotate(45deg);
      }
    

    .pagination-item .disabled {
      pointer-events: none;
    }

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      .arrow:hover {
        background-color: transparent;
        cursor: default;
      }
      .arrow-default{
        filter:invert(71%) sepia(1%) saturate(0%) hue-rotate(5deg) brightness(67%) contrast(91%);

      }
      .right-arrow-default{
        filter:invert(71%) sepia(1%) saturate(0%) hue-rotate(5deg) brightness(97%) contrast(91%);

      }
      .arrow-diable{
        filter:invert(71%) sepia(1%) saturate(0%) hue-rotate(5deg) brightness(184%) contrast(91%);

      }
    
