#hideMe {
    -moz-animation: cssAnimation 30s ease-in 30s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 30s ease-in 30s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 30s ease-in 30s forwards;
    /* Opera */
    animation: cssAnimation 30s ease-in 30s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
/* @keyframes cssAnimation {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
}
@-webkit-keyframes cssAnimation {
    to {
        width:0;
        height:0;
        visibility:hidden;
    }
} */