    .cards-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around; /* Adjust as needed */
    }
    
    .card {
        position: relative;
        margin-right: 25px;
        transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
        background-color: #fff;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 13px 13px 10px -7px rgba(0, 0, 0, 0.1);
        /* width: 248px; */
        margin-bottom: 32px;
    }
    
    .card:hover {
        box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
        transform: scale(1.10, 1.10);
    }
    
    .card__img,
    .card__img--hover {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 175px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
    
    .card__img--hover {
        transition: 0.2s all ease-out;
        position: absolute;
        top: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 235px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        opacity: 0;
    }
    
    .card__info {
        position: relative;
        z-index: 2;
        background-color: #fff;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        padding: 16px 24px 24px 24px;
    }
    
    .card__category {
        /* font-family: 'Raleway', sans-serif; */
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 2px;
        font-weight: 550;
        color: #585151;
    }
    
    .card__title {
        margin-top: 5px;
        margin-bottom: 10px;
        /* font-family: 'Roboto Slab', serif; */
    }
    
    .card__by {
        font-size: 12px;
        font-family: 'Raleway', sans-serif;
        font-weight: 500;
    }
    
    .card__author {
        font-weight: 600;
        text-decoration: none;
        color: #AD7D52;
    }
    
    .card:hover .card__img--hover {
        height: 100%;
        opacity: 0.3;
    }

    .card:hover .card__img{
        opacity: 0;
    }
    
    .card:hover .card__info {
        background-color: transparent;
        position: relative;
    }
    

    .cards-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(238px, 1fr));
        gap: 1.5rem;
        padding: 1rem;
        max-width: 1200px;
        margin: 0 auto;
    }
    
    .card {
        display: flex;
        flex-direction: column;
        height: 100%;
        border: 1px solid #e5e7eb;
        transition: all 0.3s ease;
    }
    
    .card:hover {
        transform: translateY(-2px);
    }
    
    .card__info {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }
    
    .card__title {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    @media (max-width: 768px) {
        .cards-container {
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }
    }