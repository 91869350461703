/* a#tooltipdemo {
  position: relative ;
   min-width: 100px ;
}
a#tooltipdemo:hover::after {
  content: "What is HTML? What is CSS?" ;
  position: absolute ;
  top: 1.1em ;
  left: 1em ;
  min-width: 100px ;
  border: 1px #808080 solid ;
  padding: 8px ;
  color: black ;
  background-color: white ;
  z-index: 1 ;
} */
/* first one */
/* .overflow {
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow:hover {
  overflow: visible;
}

.overflow:hover span {
  position: relative;
  margin-top: 3rem;
  background-color: white;

  box-shadow: 0 0 4px 0 black;
  border-radius: 1px;
} */

/* second one  */

.wrap {
  position: relative;
}

.overflow {
  /* white-space: nowrap;  */
  overflow: hidden;
  text-overflow: ellipsis;
  /* pointer-events:none; */
}

.overflow:after {
  content:"";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 15px;
  z-index: 1;
  /* border: 1px solid red; */
  pointer-events:initial;

}

.overflow:hover{
  cursor: pointer;
}

.tooltip {
  /* display: none; */
  position: absolute;
  top: 10;
  left: 0;
  right: 10;
  background-color: #fff;
  padding: 10px;
  -webkit-box-shadow: 0 0 50px 0 rgba(0,0,0,0.3);
  opacity: 0;
  z-index: 1;
  width: fit-content;
  transition: opacity 0.5s ease;
}

.overflow:hover + .tooltip {
   display: flex;
   flex-direction: column;
  transition: opacity 0.5s ease;
  opacity: 1;
  /* text-transform: unset !important;
  width: 89%;
  word-break: break-all;
  top: 22px; */
}

/* v1 code  */
/* .linkanchor {
  position: absolute;
  background: #fff;
  color: #4e4e4e !important;
  text-transform: capitalize;
  font-size: 12px;
  padding: 5px;
  display: none;
  z-index: 1000;
  top: 50px;
   border: 1px solid;
  line-height: 12px;
  box-shadow: 5px 4px 5px #969393;
}

.linkanchor {
  text-transform: unset !important;
  width: 89%;
  word-break: break-all;
  top: 22px;
}

.newslinktooltip a {
  font-size: 10pt !important;
  font-weight: 600 !important;
  display: block;
}

.newslinktooltip a:hover::after {
  font-size: 10pt !important;
  font-weight: 600 !important;
  display: none;
}

.linkanchor a {
  display: block;
  font-weight: 500 !important;
} */

/* 
$(".newslinktooltip a").mouseover(function() {
            $($(this).attr('data-target')).css("display", "block");
        });
        $(".newslinktooltip a").mouseout(function() {
            $($(this).attr('data-target')).css("display", "none");
        }); */
