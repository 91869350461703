#searchBarBrowse::-webkit-search-cancel-button { 
  -webkit-appearance: none;
 
  background-repeat: no-repeat;
  padding:1px;
  background-position: center center;
  background-size: 25px 25px;
  background-image: url('../../images/search_close_button.png');
  position: fixed;

  
  box-sizing: border-box;
  height: 30px;
  width: 30px;

  vertical-align: middle;
  margin-left: 1.5em;
  border-radius: 1em;  
}
input#searchBarBrowse>div{
  box-shadow: none !important;
  background: rgba(255, 255, 255, 0.9);

}

#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 6rem;
  top: 1rem;
  background-image: url(../../images/menu_lines.svg);
  background-repeat: no-repeat;
  opacity: 0.2;

}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  /* background: #373a47; */
  display: none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  
  margin-top: 10px;
  
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
  width: 100px;
  height: 100px;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  /* padding: 2.5em 1.5em 0; */
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
