.btn-container {
    display: inline-block;
    text-align: center;
    border: 1px solid #A26F35;

}

.btn-container label {
    position: relative;
    cursor: pointer;
    font-size: 15px;
    display: inline-block;
    /* width: 140px; */
    /* height: 40px; */
    text-transform: uppercase;
    /* letter-spacing: 5px; */
    /* padding: 8px 0; */                                                                                                                                                                                                                                                                                                                                               
    transition: all 0.3s ease;
    
}

#t + label:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: -1;
    background-color: #b5844c;
    transition: all 0.3s ease;
}

#t:checked + label:before {
    left: 0;
}

#t:checked + label,
#l:checked + label {
    color: #efefef;
}

/* SubcategorySelector.css */
    
.btn-container2 {
    display: inline-block;
    text-align: center;
    /* margin: 8px ; */
    /* height: 45px; */
    /* padding-left: 2px; */
    border: 1px solid #A26F35;
}

.btn-container2 label {
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 70px;
    /* height: 40px;    */
    text-transform: uppercase;
    padding: 7px 0;
    transition: all 0.3s ease;
}
    
    #PE:checked + label,
    #MA:checked + label {
        color: #efefef;
    }
    
    #PE + label:before{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        z-index: -1;
        background-color: #b5844c;
        transition: all 0.3s ease;
    }
    
    #PE:checked + label:before{
        left: 0;
    }
