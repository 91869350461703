.pagination{
  display: flex;
  justify-content: center;
}

li.pagination__item.page-item.active{
  background-color: white;
  padding: 0 3px;
  
}
li.pagination__item{

  margin: 0px 5px 0 5px;
  color: #666666;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  
}
button.pagination__link.page-link{
    margin: 0 3px;
  
}
.InvestorTable{
  height: 497px !important;
}
