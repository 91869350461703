.DraftEditor-editorContainer{
   margin-bottom: 10px;
}
.Dropdown__root___3ALmx{
    display: none;
}
.RichTextEditor__root___2QXK-{
    height: 17rem;
}
/* .RichTextEditor__editor___1QqIU {
  cursor:default !important;
} */
.public-DraftStyleDefault-block > span{
    font-family: 'Sequel-Sans-BookBody';
    /* font-weight: normal !important;
    font-size: 15px;
    color: #333333;
    letter-spacing: -0.34px;
    line-height: 22px;
    width: 1108px; */
    cursor:text;
}
.css-dev-only-do-not-override-j2c068.ant-tooltip-placement-top{
    z-index: auto;
}

@media only screen and (max-width: 640px){
    .public-DraftEditor-content{
        height: 8.5rem;
     }
    .public-DraftEditor-content::-webkit-scrollbar {
      height:0px;
      width:0px;
    }
    }