.month_picker div:nth-of-type(1){
    border: 1px solid #D2D2D2 !important;
    min-width:130px !important;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;   
    text-align: center;
    font-size: 13px;
    line-height: 15px;
 }
 .month_picker div:nth-of-type(1)>div:nth-of-type(1)>svg{
     display: none !important;

 }
 .month_picker div:nth-of-type(1)>div:nth-of-type(1){
    border: none !important;
    padding-left:4px; 
    padding-top:0;
    padding-bottom: 4px;
    padding-right:4px;

}

.month_picker div:nth-of-type(1)>div:nth-of-type(2){
    width: 333px !important;

}

.month_picker div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1){
    border: none !important;

}

 .month_picker div:nth-of-type(1)>div:nth-of-type(1)>div:nth-of-type(1)::after{
    background-image: url(Calender.png) !important;
    margin-left: 0rem;
    content: "";
    background-size: 16px 18px;
    background-repeat: no-repeat;
    display: inline-block !important;
    width: 21px;
    height: 21px;
    position: relative;
    top: 8px;
    left: 10px;
}

.month_picker div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(1){
    display: none !important;
}

.month_picker div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(2){
    width: 285px !important;
}

.month_picker div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(3)>div{
    border: #BC8B20 1px solid !important;
    border-radius: 5px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-width: 50px !important;
    /* background-color: white; */
}

.month_picker div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(3)>div>.selected{
    background-color: #BC8B20 !important;
    color: white;
}

@media (max-width: 993px){
    .month_picker div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1){
        /* border: #D2D2D2 1px solid; */
        text-align: center;
        border-radius: 5px;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        min-width: 17rem !important;
     }
     .month_picker div:nth-of-type(1)>div:nth-of-type(2){
        position: fixed;
        top: 50%;
        padding-top: 1rem !important;
     }
     .month_picker div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(3)>div{
        border: #BC8B20 1px solid;
        border-radius: 5px;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        min-width: 20px !important;
    }
    }
    @media(max-width: 993px){
        .month_picker div:nth-of-type(1)>div:nth-of-type(2){
            position: fixed;
            top: 28%;
            padding-top: 1rem !important;
            
         }
         .fjPsQq{
            right: 345px !important;
         }
    }
    @media(max-width: 768px){
        .month_picker div:nth-of-type(1)>div:nth-of-type(2){
            position: fixed;
            top: 39% !important;
            padding-top: 1rem !important;
           
         }
         
         .fjPsQq {
   
            right: 10px !important;
            z-index: 99999;
           
        }
        .ja-dwwr {
            margin-top: -5px !important;

        }
        .sc-hLBbgP, .fjPsQq {
            z-index: 1 !important;
        }
    } 
   
    @media(max-width:993px){
        .fjPsQq, .eSFkfe {
           border :none !important;
            right: 12px !important;
            margin-top: 7px !important;
            
        
    }
    .sc-ftTHYK, .dhuQxO{
        margin-top: 7px !important;
    }  
      
}
@media(max-width:993px){
.month_picker div:nth-of-type(1)>div:nth-of-type(2){
    width: 350px !important;

}
}
@media(max-width:993px){
.sc-ftTHYK {
    border: none !important;
    min-width: 17rem !important;

}
}
/* .fjPsQq, .eSFkfe {
   
    right: 1px;
    z-index: 99999;
} */

/* .dzfCxQ {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    padding: 9px 10px !important;
} */
/* .CuNLr{
border: #eee 1px solid;
    border-radius: 5px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-width: 170px !important;
    background-color: white;
} */
/* .btkeiI, .sc-gKPRtg{
    display: none !important;
}
.hTEdev{
    min-width: 0px !important;
    border: none !important;
}
.cFZkCj, .jeDpBN{
    border-color: #BC8B20 !important;
}
.dPOxbO {
    border: #eee 0px solid !important;
    min-width: 140px !important;
}
.jpjDDR {
    padding: 3px !important;
    border: #BC8B20 1px solid !important;
}
.eeOlSf {
    font-size: 12px !important;
    width: 365px !important;
}
.fjPsQq, .eSFkfe {
    background-color: white;
    border: #eee 1px solid;
    position: absolute;
    top: 40px !important;
    right: 0;
    z-index: 99999;
    width: 350px !important;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-radius: 5px;
    box-shadow: 0 0 10px 0rgb(0 0 0 / 20%);
}
.jpjDDR.selected {
    background-color: #BC8B20 !important;
    color: white;
}
.iuxhhH {
    padding: 0px 10px 4px 10px !important;
    font-size: 12px !important;
    margin:-2px 0 0 0px !important;
}
svg.svg-inline--fa.fa-chevron-down.fa-w-14{
    display: none;
}
.sc-gswNZR::after{
    background-image: url(Calender.png) !important;
    margin-left: 0rem;
    content: "";
    background-size: 16px 18px;
    background-repeat: no-repeat;
    display: inline-block !important;
    width: 21px;
    height: 21px;
    position: relative;
    top: 1px;
    left: 10px;
}
@media (max-width: 993px){
.eeOlSf {
    position: absolute;
    left: 0rem;
    width: 240% !important;
}
}
.jjobxD {
    padding: 13px !important;
    border-bottom: #eee 1px solid;
}
.navlink{
    
    color: #A5711A!important;
  }
  @media (max-width: 993px) {

  .iuxhhH  {
        margin:-5px 0 0 0px !important;
    }
    
  } */