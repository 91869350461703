.profiletable:last-child {
    border-bottom: none !important;
}
.profiletable:nth-last-child(2) {
    border-bottom: none;
}
.profiletable:nth-last-child(3) {
    border-bottom: none;
}
.profiletable:nth-last-child(4) {
    border-bottom: none;
}
.hightlightstable:last-child {
    border-bottom: none !important;
}
.angledealinvestor:last-child{
    border-bottom: none !important;
}
.css-dev-only-do-not-override-j2c068.ant-tooltip-placement-top{
    z-index: auto;
}