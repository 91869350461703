.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menuimg a {
  background-color: #BC8B20; 
}

.float-left {
  float: left;
}

.advanceFilterCloseBtn{
  display: block;
}

/* .advanceFilterCloseBtn button:hover, .advanceFilterCloseBtn button:focus{
  display: inline-block !important;
} */

.advanceFilter:hover .advanceFilterCloseBtn{
  display: inline-block;
}

.AdvanceFilterSearch .ant-select-selection-overflow{
}

.singleSearch .ant-select-selector{
  height: 40px !important;
  border: 1px solid #D9BD7F !important;
  border-radius: 0.25rem !important;
}
.singleSearch .ant-select-selection-item{
  font-size: 15px !important;
  padding: 0;
  line-height: 38px !important;
}
.singleSearch .ant-select-selection-placeholder{
  font-size: 15px !important;
  padding: 0;
  line-height: 38px !important;
}
.multiSearch .ant-select-selector{
  max-height: 40px !important;
}
.overflowText{
  white-space: nowrap; 
  width: 11rem; 
  overflow: hidden;
  text-overflow: ellipsis; 
  -webkit-box-shadow: 10px 10px 8px -10px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 8px -10px rgba(0,0,0,0.75);
box-shadow: 10px 10px 8px -10px rgba(0,0,0,0.75);
}
.qb-container button,.dynamic-filter-component input[type=text], .dynamic-filter-component input[type=number], .ant-select-selector, .multiSearch .ant-select {
  text-overflow: ellipsis; 
  -webkit-box-shadow: 10px 10px 8px -10px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 8px -10px rgba(0,0,0,0.75);
box-shadow: 10px 10px 8px -10px rgba(0,0,0,0.75);
}
.company_tag_input input[type=text] {
  box-shadow: none !important;
}
.no-shadow {
  box-shadow: none !important;
}
.company_tag_input .rti--container {
  width: 20rem;
  border: none !important;
  border-bottom: 1px solid #BC8B20 !important;
  max-height: 40px;
  overflow-y: auto;
  min-width: 10rem;
  border-radius: 0px !important;
  --rti-main: none !important;
}

.company_tag_input .rti--tag {
  background: rgba(0, 0, 0, 0.06);
  font-size: 14px;
}

.company_tag_input .rti--tag button{
  color: gray;
}

.company_tag_input .rti--tag button:hover{
  color: #000;
}

.ant-pagination-options{
  display: none !important;
}

.advanceSearchScroll .ant-modal-content{
  padding: 20px 3px 20px 24px !important;
}
.advanceSearch a:hover{
  color: #A3751F !important;
}

@media screen and (max-width: 880px) {
  .ant-pagination .ant-pagination-item{
    margin-inline-end: 3px !important;
  }
}

  @media only screen and (max-device-width: 851px) and (orientation: landscape){
    .listPage td , .listPage th{
      max-width: 240px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      cursor: default;
    }
    
  }
  @media only screen and (max-device-width: 851px) and (orientation: portrait)  {
    .listPage td , .listPage th{
      max-width: 240px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      cursor: default;
    }
  }

.mark-custom mark {
  color: #e0a524; /* Highlight text color */
  background: none; /* Remove background color */
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}.ant-tooltip-inner{
      font-size: 12px;
    }
    .gs-scrollbar::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    .gs-scrollbar::-webkit-scrollbar-thumb{
      background-color: #cfaf6a;
    }
