/* Scoped styles for SearchForm component */
.search-form button {
    border: none;
    background: none;
    color: inherit;
    cursor: pointer;
    color: aliceblue;
}

.search-form .dropdown {
    position: relative;
}

.search-form .dropdown__button {
    background-color: #b5844c;
    border-radius: 5px;
    border: solid 1px #d68c37;
}

.search-form .dropdown__button i {
    margin-left: 1rem;
}

.search-form .dropdown__button:hover {
    background-color: #b5844cd2;
}

.search-form .dropdown__items {
    background-color: #c2935d;
    border: solid 1px #d68c37;
    position: absolute;
    margin-top: 0.5rem;
    left: 0;
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: open 0.3s;
    transform-origin: top left;
}

.search-form .industry_dropdown__items {
    background-color: #c2935d;
    border: solid 1px #d68c37;
    position: absolute;
    margin-top: 0.5rem;
    left: 0;
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: open 0.3s;
    transform-origin: top left;
}

.search-form .dropdown__item {
    width: 100%;
    text-align: left;
    padding: 1rem;
}

.search-form .dropdown__item:hover {
    background-color: #495057;
    border-radius: 0.5rem;
}

.search-form .dropdown--hide {
    display: none;
}

.search-form .dropdown--show {
    display: block;
}

@keyframes open {
    from {
        transform: scaleY(0);
    }

    to {
        transform: scaleY(1);
    }
}
